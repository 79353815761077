
//.entries-container-inner{
.entries{
    .entries-line {
        position: absolute;
        top: 49px;
        left: calc(50% + 5px);
        bottom:0px;
        transform:translateX(-50%);
        width:4px;
        height: 100px;//2640px;
        background-color:#043c9d;
        z-index:1;
        @media(max-width: 768px){
            left:-10px;
            top:32px;
        }
        @media(max-width: 416px){
            top:32px;
        } 
    }
}
.entries {
    width:calc(100% - 50px);
    max-width:800px;
    margin:auto;
    position: relative;
    left:-5px;
    .entry {
        width:calc(50% - 50px);
        float:left; 
        padding:20px;
        clear:both;
        text-align:right;
        @media(max-width: 768px){
            width:100%;
            text-align:left;
        }
        &:not(:first-child) {
            margin-top:-60px;
            @media(max-width: 768px){
                margin-top:0px;
            }
        }
        .title {
            font-family: 'Telegraf';
            font-weight: 500;
            font-size:40px;
            line-height: 45px;
            margin-bottom:12px;
            position: relative;
            color:#043c9d;
            @media(max-width: 768px){
                font-size:30px;
                line-height: 36px;
                margin-bottom:8px;
            }
            &:before {
                content: '';
                position: absolute;
                width:15px;
                height:15px;
                border:4px solid #043c9d;
                background-color:#FFF;
                border-radius:100%;
                top:50%;
                transform:translateY(-50%);
                right:-82px;
                z-index:4;
                @media(max-width: 768px){
                    right:auto;
                    left: -37px;
                }
            }
        }
        .body {
            p {
                font-family: 'Manrope';
                font-weight: 400;
                font-size:14px;
                line-height: 18px;
                color:#504950;
                margin-bottom: 5px;
            }
        }
        &:nth-child(2n) {
            text-align:left;
            float:right;
            .title {
                &:before {
                    left:-72px;
                }
            }
            @media(max-width: 768px){
                float:left;
                text-align:left;
                .title {
                    &:before {
                        left:-37px;
                    }
                }
            }
        }
    }
}