
.popup-overlay{
    background:rgba(0,0,0,0.5);
    width:100vw;
    height:100vh;
    position: fixed;
    top:0px;
    z-index: 1000;
    //display: none;
    &.open{
        display:block;
    }
}
.popup-content {
    
    margin:10vh auto 0;
    width:80%;
    max-width: 1000px;
    
    .popup-header {
        display: flex;
        justify-content: space-between;
        background-image: linear-gradient(to top right,#103f99,#2d2b2e,#2843a1);
        width:100%;
        padding:10px 35px;
        h3 {
            color: #fff;
            font-family: 'Telegraf';
            font-size: 23px;
            line-height: 30px;
            font-weight: 400;
            margin:0;
        }

        h4 {
            color: #fff;
            font-family: 'Telegraf';
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            margin:0;

            @media (max-width: 576px) {
                display: none;
            }
        }

        .close-content {
            display: flex;
            cursor: pointer;
            .close {
                background: url(../images/general/av-popup-close.png) center center no-repeat;
                background-size: contain;
                width: 25px;
                height: 30px;
                color: #fff;
                margin-left: 25px;
            }
        }


    }

    .popup-body {
        padding:35px 35px 20px 35px;
        margin:0 !important;
        background-color: #FFF;
        p {
            font-family: 'Manrope';
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: #433d43;
            text-align: center;
        }
    }
}