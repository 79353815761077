body.quienes-somos, body.about-us {
    width: 100%;
    height: 100%;
    background: transparent;
    padding-top: 85px;

    @media(max-width: 1200px) {
        padding-top: 70px;
    }

    header {
        background: #FFF;
    }

    .section-row {

        &.section-1 {
            @media(max-width: 800px) {
                background: url('../images/quienes-somos/Enc_QuienesSomos.jpg') center center no-repeat;
                background-size: cover;
            }
        }

        &.section-2 {
            background: #FFF;

            .content-fluid {
                max-width: 1140px;
            }

            .text-container {
                padding: 100px 0 0px;

                @media(max-width: 1200px) {
                    padding: 50px 0 20px;
                }
            }

            p {
                font-family: 'Manrope';
                font-weight: 300;
                font-size: 22px;
                line-height: 28px;
                color: #7c7c7c;
                margin-bottom: 30px;

                @media(max-width: 1200px) {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            img {
                width: 100%;
                position: relative;
                bottom: -70px;
                display: inline-block;

                @media(max-width: 1200px) {
                    display: none;
                }
            }

            .img {
                display: none;
                width: 100%;
                height: 250px;
                background: url('../images/quienes-somos/Enc_QuienesSomos.jpg') center center no-repeat;
                background-size: cover;

                @media(max-width: 1200px) {
                    display: block;
                }
            }
        }


        &.section-3 {
            background: #eeedef;
            padding: 70px 0;

            @media(max-width: 1200px) {
                padding: 0px 0 50px;
                background-color: #f2f2f2;
            }

            .content-fluid {
                max-width: 1140px;
            }

            h3 {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 40px;
                line-height: 45px;
                padding: 60px 0 13px;
                border-bottom: 1px solid #2843A1;
                text-align: center;

                @media(max-width: 1200px) {
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 26px;
                    padding: 50px 0 20px;
                }
            }

            .items {
                padding: 60px 0;

                @media(max-width: 1200px) {
                    padding: 20px 0 0;
                }

                div {
                    width: 50%;
                    float: left;
                    height: 260px;
                    text-align: center;
                    padding: 60px;

                    &:nth-child(1) {
                        border-bottom: 1px solid #cbd0d4;
                        border-right: 1px solid #cbd0d4;
                    }

                    &:nth-child(2) {
                        border-bottom: 1px solid #d0c4d6;
                    }

                    &:nth-child(3) {
                        border-right: 1px solid #d0c4d6;
                    }

                    @media(max-width: 1200px) {
                        float: none;
                        width: 100%;
                        height: auto;
                        border: 0px !important;
                        padding: 20px 0;
                    }
                }
            }

            h4 {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 600;
                font-size: 28px;
                line-height: 38px;

                @media(max-width: 1200px) {
                    font-size: 19px;
                    line-height: 23px;
                }
            }

            p {
                color: #2843A1;
                font-family: 'Manrope';
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;

                @media(max-width: 1200px) {
                    font-size: 18px;
                    line-height: 23px;
                }
            }

        }


        &.section-4 {
            background: #FFF;
            padding: 90px 0 0;

            @media(max-width: 1200px) {
                padding: 50px 0 0;
            }

            .content-fluid {
                max-width: 1140px;
            }

            h3 {
                color: #134093;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 40px;
                line-height: 45px;
                margin-bottom: 20px;

                @media(max-width: 1200px) {
                    display: none;
                }
            }

            h5 {
                color: #134093;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 25px;
                line-height: 28px;
                margin-bottom: 24px;

                @media(max-width: 1200px) {
                    font-size: 18px;
                    line-height: 23px;
                    text-align: center;
                    padding: 0 20px;
                }
            }

            p {
                color: #134093;
                font-family: 'Manrope';
                font-weight: 300;
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 20px;

                @media(max-width: 1200px) {
                    display: none;
                }
            }

        }

        &.section-5 {
            background: #FFF;
            padding: 0px 0 90px;

            @media(max-width: 1200px) {
                padding: 0px 0 30px;
            }

            .partners {
                padding-top: 80px;

                @media(max-width: 1200px) {
                    padding-top: 20px;
                }

                h4 {
                    @media(max-width: 1024px) and (min-width: 767px) {
                        font-size: 30px;
                        line-height: 35px;
                    }
                }

            }

            .management {
                h4 {
                    @media(max-width: 1024px) and (min-width: 767px) {
                        font-size: 30px;
                        line-height: 35px;
                    }
                }
            }
        }

        &.section-6 {
            background: #FFF;
            padding: 100px 0 90px;

            @media(max-width: 1200px) {
                padding: 0px 0 30px;
            }

            .content-fluid {
                max-width: 1140px;
            }

            h3 {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 40px;
                line-height: 45px;
                margin-bottom: 16px;
                padding-bottom: 10px;
                text-align: center;
                border-bottom: 1px solid #2843A1;

                @media(max-width: 1200px) {
                    font-size: 22px;
                    line-height: 26px;
                    padding: 50px 0 20px;
                }

                @media(max-width: 1024px) and (min-width: 767px) {
                    font-size: 30px;
                    line-height: 35px;
                }
            }

            h4 {
                color: #b1afb1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 25px;
                line-height: 28px;
                margin-bottom: 24px;
                text-align: center;

                @media(max-width: 1200px) {
                    font-size: 18px;
                    line-height: 23px;
                    padding: 0 20px;
                }
            }

            .entries-container {
                height: 80vh;
                overflow: auto;
            }

        }

        .people-container {
            h4 {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 30px;
                line-height: 38px;
                text-align: center;
                margin-bottom: 30px;

                @media(max-width: 1200px) {
                    font-size: 22px;
                    line-height: 26px;
                    margin-top: 20px;
                }
            }

            .people-list {
                text-align: center;
                position: relative;

                .people-item {
                    display: inline-block;
                    width: 100%;
                    max-width: 310px;
                    margin: 0px 10px 60px;
                    transition: all 0.4s;

                    @media(max-width: 1200px) {
                        margin: 0px 10px 40px;
                    }

                    .img-cont {
                        transition: all 0.4s;
                        overflow: hidden;
                        padding-top: 292px / 292px * 100%;
                        position: relative;
                        margin-bottom: 20px;
                        height: 292px;
                        cursor: pointer;
                        z-index: 1;

                        .img {
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            height: 100%;
                            width: 100%;
                            transition: transform 0.4s;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                        }

                        &:hover {
                            .img {
                                transform: scale(1.2);
                            }
                        }
                    }

                    h5 {
                        color: #2843A1;
                        font-family: 'Telegraf';
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 20px;
                        margin: 0;
                        transition: all 0.4s;
                    }

                    span {
                        color: #2843A1;
                        font-family: 'Telegraf';
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 20px;
                        transition: all 0.4s;
                    }

                    .people-item-description {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        opacity: 0;
                        z-index: -1;
                        transition: all 0.4s;
                        text-align: left;
                        border-top: 1px solid #2843A1;
                        padding: 50px 20px 50px;

                        @media(max-width: 576px) {
                            padding: 50px 10px 50px;
                        }

                        .close {
                            content: '';
                            background: url('../images/quienes-somos/ic-close.png') center center no-repeat;
                            background-size: contain;
                            width: 76px;
                            height: 76px;
                            z-index: 2;
                            cursor: pointer;
                            margin: 70px auto 0;

                            @media(max-width: 1200px) {
                                margin: 40px auto 0;
                            }
                            
                        }

                        h6 {
                            color: #2843A1;
                            font-family: 'Telegraf';
                            font-weight: 400;
                            font-size: 25px;
                            line-height: 28px;
                            margin: 0 auto;
                            margin-bottom: 26px;
                            max-width: 1140px;

                            @media(max-width: 1200px) {
                                font-size: 20px;
                                line-height: 25px;
                            }
                        }

                        p {
                            color: #2843A1;
                            font-family: 'Manrope';
                            font-weight: 200;
                            font-size: 20px;
                            line-height: 28px;
                            margin: 0 auto;
                            max-width: 1140px;

                            @media(max-width: 1200px) {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }

                    &.people-item-open {
                        .people-item-description {
                            opacity: 1;
                            z-index: 2;
                        }
                    }

                    &.people-item-not-open {
                        .img-cont {
                            opacity: 0.6;
                        }

                        &>h5 {
                            opacity: 0.6;
                        }

                        &>span {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }


    }

}