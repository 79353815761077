@keyframes scroll-link {
    0% {
        bottom: 50px;
    }

    50% {
        bottom: 40px;
    }

    100% {
        bottom: 50px;
    }
}

.parallax-mirror {
    @media(max-width: 800px) {
        display: none;
    }
}

body.home {
    width: 100%;
    height: 100%;
    background: transparent;

    // header {
    //     background: transparent;
        
    // }

    header.on-scroll {
        background: #ffffff;
        .inner-header{
            visibility: visible !important;
        }
    }

    .section-row {

        &.section-1-home {
            width: 100%;
            height: 100vh;
            overflow: hidden;
            position: relative;
            background: url('../images/home/Enc_Home.jpg') no-repeat center center fixed;
            background-size: cover;
            @media(max-width: 991px) {
                background-position: 22% bottom;
            }

            // video#myVideo {
            //     position: absolute;
            //     left: 50%;
            //     top: 50%;
            //     transform: translate(-50%, -50%);
            //     z-index: 1;

            //     @media screen and (max-aspect-ratio: 2120/1080) {
            //         height: 100%;
            //     }

            //     @media screen and (min-aspect-ratio: 2120/1080) {
            //         width: 100%;
            //     }
            // }

            .content-fluid {
                position: absolute;
                z-index: 3;
                width: 100%;
                max-width: 100%;
            }

            p {
                text-align: center;
                color: #FFF;
                font-family: 'Telegraf';
                font-weight: 300;
                font-size: 57px;
                line-height: 68px;
                padding-top: 37vh;
                text-shadow: 0 0px 46px rgba(0, 0, 0, 0.75);

                @media(max-width: 1200px) and (min-width: 767px) {
                    font-size: 45px;
                    line-height: 50px;
                }

                @media(max-width: 767px) {
                    font-size: 30px;
                    line-height: 34px;
                }

            }

            a.arrow-down {
                position: absolute;
                bottom: 50px;
                left: 50%;
                margin-left: -15px;
                background: url(../images/home/ic_arrow-down.png) center center no-repeat;
                background-size: contain;
                width: 31px;
                height: 16px;
                animation: scroll-link 1s infinite 1s;
                z-index: 4;

                &:hover {
                    animation-play-state: paused;
                }
            }
        }

        &.section-2 {
            position: relative;
            background: #FFF;
            width: 100%;
            text-align: center;
            padding: 140px 0 170px;

            @media(max-width: 991px) {
                padding: 70px 0 70px;
            }

            p {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 200;
                font-size: 40px;
                line-height: 50px;
                margin: 0 0 50px;

                @media(max-width: 991px) {
                    font-size: 21px;
                    line-height: 26px;
                    margin: 0 0 30px;
                }
            }

            a.btn {
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 26px;
                line-height: 37px;
                color: #2843A1;
                border-bottom: 1px solid #2843A1;

                &:hover {
                    text-decoration: none;
                    border-bottom: 1px solid transparent;
                }

                @media(max-width: 991px) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }

        }

        &.section-3 {
            position: relative;
            background-color: #f0f1f5;
            background-size: cover;
            width: 100%;
            padding: 70px 0 80px;

            @media(max-width: 991px) {
                padding: 30px 0 30px;
            }

            .row {
                padding-top: 35px;
                padding-bottom: 35px;

                @media(max-width: 991px) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }

            .text-content {
                display: flex;
                height: 100%;
                vertical-align: middle;
                align-items: center;

                &.content-right {
                    padding-left: 70px;
                }

                &.content-left {
                    padding-right: 70px;
                }

                @media(max-width: 991px) {
                    padding-top: 25px;

                    &.content-right {
                        padding-left: 0px;
                    }

                    &.content-left {
                        padding-right: 0px;
                    }
                }
            }

            img {
                width: 100%;
            }

            h5 {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 30px;
                line-height: 50px;
                margin-bottom: 35px;

                @media(max-width: 991px) {
                    font-size: 20px;
                    line-height: 20px;
                    margin-bottom: 15px;
                }
            }

            p {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 100;
                font-size: 25px;
                line-height: 35px;
                margin-bottom: 40px;

                @media(max-width: 991px) {
                    font-size: 19px;
                    line-height: 21px;
                    margin-bottom: 26px;
                }
            }

            a.btn {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 20px;
                line-height: 35px;

                span {
                    border-bottom: 1px dotted #2843A1;
                    margin-right: 10px;
                }

                &:hover {
                    text-decoration: none;

                    span {
                        border-bottom: 1px dotted transparent;
                    }
                }

                @media(max-width: 991px) {
                    font-size: 16px;
                    line-height: 16px;
                }
            }

            @media(max-width: 800px) {}
        }

        &.section-4 {
            position: relative;
            background: #F2F2F2;
            width: 100%;
            padding: 100px 0 150px;

            @media(max-width: 991px) {
                padding: 35px 0 100px;
            }

            .row {
                padding-top: 0;
                padding-bottom: 0;
            }

            h3 {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 30px;
                line-height: 50px;
                margin-bottom: 45px;

                @media(max-width: 991px) {
                    font-size: 22px;
                    line-height: 25px;
                    text-align: center;
                    margin-bottom: 30px;
                }
            }

            .card-container {
                padding-bottom: 25px;
            }

            .card {
                border-radius: 3px;
                background-color: #FFF;
                overflow: hidden;
                position: relative;
                height: 480px;
                margin-bottom: 30px;
                display: block;

                @media(max-width: 576px) {
                    height: auto;
                }

                &:hover {
                    text-decoration: none;

                    .img {
                        transform: scale(1.2);
                    }
                }

                .img-cont {
                    height: 0;
                    overflow: hidden;
                    padding-top: 241px / 363px * 100%;
                    background: white;
                    position: relative;

                    .img {
                        background: url('../images/home/quienes-somos.jpg') center center no-repeat;
                        background-size: cover;
                        height: 100%;
                        width: 100%;
                        transition: transform 0.4s;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }

                    .img-inner {
                        background: url('../images/home/bg_blog-note.png') left bottom no-repeat;
                        background-size: contain;
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                    }
                }

                p {
                    color: #433d43;
                    font-family: 'Telegraf';
                    font-weight: 100;
                    font-size: 23px;
                    line-height: 28px;
                    margin-bottom: 40px;
                    padding: 30px 25px;

                    @media(max-width: 991px) {
                        font-size: 20px;
                        line-height: 25px;
                        margin-bottom: 50px;
                    }
                }

                .btn {
                    position: absolute;
                    bottom: 30px;
                    left: 25px;
                    color: #433d43;
                    font-family: 'Telegraf';
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 18px;
                    border-bottom: 1px dotted #433d43;

                    @media(max-width: 991px) {
                        font-size: 16px;
                        line-height: 18px;
                    }

                    &:hover {
                        text-decoration: none;
                        border-bottom: 1px dotted transparent;
                    }
                }
            }

            .btn.btn-purple {
                display: block;
                background: #2843A1;
                background-image: linear-gradient(to top right, #2843A1, #302f31);
                color: #FFF;
                font-family: 'Telegraf';
                font-weight: 300;
                font-size: 21px;
                line-height: 23px;
                padding: 8px 25px 6px;
                margin: 0 auto;
                max-width: 200px;
                text-align: center;
                border-radius: 3px;
                transition: all 0.4s;
                opacity: 1;

                @media(max-width: 991px) {
                    font-size: 20px;
                    line-height: 22px;
                    padding: 10px 20px 8px;
                }

                &:hover {
                    opacity: 0.8;
                    text-decoration: none;
                }
            }
        }


        &.section-5 {
            position: relative;
            background-image: linear-gradient(to top right, #103f99, #2d2b2e, #2843A1);
            width: 100%;
            text-align: center;
            padding: 120px 0 130px;
            margin: 0;
            border-bottom: 4px solid #FFF;

            @media(max-width: 991px) {
                background-image: linear-gradient(to top right, #2843A1, #302f31);
                padding: 110px 0 120px;
                border-bottom: 4px solid #FFF;
            }

            p {
                color: #FFF;
                font-family: 'Telegraf';
                font-weight: 200;
                font-size: 38px;
                line-height: 42px;
                margin: 0 0 15px;

                @media(max-width: 991px) {
                    font-size: 25px;
                    line-height: 27px;
                    margin: 0 0 5px;
                }
            }

            a.btn {
                font-family: 'Telegraf';
                font-weight: 200;
                font-size: 25px;
                line-height: 30px;
                color: #FFF;
                border-bottom: 1px solid #FFF;

                @media(max-width: 991px) {
                    font-size: 18px;
                    line-height: 20px;
                }

                &:hover {
                    text-decoration: none;
                    border-bottom: 1px solid transparent;
                }
            }
        }



        //SECTION VIDEO
        &.section-video {
            width: 100%;
            padding: 0;

            .video {
                background: #839191;
                width: 100%;
                height: 90vh;

                iframe {
                    width: 100%;
                    height: 90vh;
                }
            }

            //TEST
            .video {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
                max-width: 100%;
                height: auto;
            }

            .video iframe,
            .video object,
            .video embed {
                position: absolute;
                top: 0%;
                width: 100%;
                height: 100%;
            }

        }

        //SECTION SLIDER
        &.section-slider {
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        height: 100vh;
                        max-height: 800px;
                        width: 100%;
                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;

                        @media(max-width: 1200px) {
                            height: 650px;
                        }

                        @media(max-width: 800px) {
                            height: 440px;
                        }

                        &-inner {
                            width: 100%;
                            height: 100%;
                            background-size: cover;
                            background-position: center center;
                            background-repeat: no-repeat;
                        }
                    }

                }

                .swiper-pagination {
                    bottom: 50px;

                    .swiper-pagination-bullet {
                        width: 15px;
                        height: 15px;
                        border: 1px solid #FFF;
                        opacity: 1;
                        background-color: transparent;
                        margin: 0 8px;

                        &-active {
                            background-color: #FFF;
                        }
                    }

                }

                .swiper-button-next,
                .swiper-button-prev {
                    width: 51px;
                    height: 101px;
                    top: 47%;

                    @media(max-width: 800px) {
                        display: none;
                    }
                }

                .swiper-button-next {
                    background: url('../images/general/ic_next.png') center center no-repeat;
                    right: 40px;
                }

                .swiper-button-prev {
                    background: url('../images/general/ic_prev.png') center center no-repeat;
                    left: 40px;
                }

            }
        }


    }

}