body.canales-de-venta, body.distribution-channels {
    width: 100%;
    height: auto;
    background: transparent;
    padding-top: 85px;

    @media(max-width: 1200px) {
        padding-top: 70px;
    }

    header {
        background: #FFF;
    }

    .section-row {

        &.section-1 {
            // background: url('../images/canales/bg_hero.jpg') center center no-repeat;
            // background-size: cover;
            @media(max-width: 800px) {
                background: url('../images/canales/Enc_Canales.jpg') center center no-repeat;
                background-size: cover;
            }
        }

        &.section-2 {
            background: #FFF;

            @media(max-width: 992px) {
                display: none;
            }

            .content-fluid {

                background-color: #fff;

                .row {
                    background-color: #fff;

                    .col-sm-12 {

                        .img-container {
                            display: block;
                            text-align: center;
                            padding: 50px 0 50px 0;
                            background-color: #fff;
                            border-bottom: solid 0.5px #B4AFB6;
                            margin-bottom: 80px;

                            img {
                                //margin: 0 4%;

                                @media(max-width: 1280px) {
                                    //margin: 0 2%;
                                }
                            }
                        }
                    }

                }

            }
        }

        &.section-3 {
            background-color: #FFF;

            .list-container {
                margin-bottom: 100px;

                h5 {
                    font-family: 'Manrope';
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: 600;
                    color: #2843A1;
                    height:55px;

                    @media(max-width: 991px) {
                        font-size: 15px;
                        line-height: 20px;
                        margin-bottom: 0px;
                        height:auto;
                    }

                }

                .list-1 {
                    @media(max-width: 992px) {
                        display: none;

                        .list-mobile {
                            display: block;
                        }
                    }

                    h3 {
                        font-family: 'Telegraf';
                        font-size: 28px;
                        line-height: 35px;
                        font-weight: 300;
                        color: #2843A1;
                        margin-bottom: 60px;
                        padding-right: 20px;

                        @media(min-width: 768px) and (max-width: 1440px) {
                            font-size: 28px;
                            line-height: 35px;
                        }
                    }

                    .btn.btn-purple {
                        display: block;
                        background: #2843A1;
                        background-image: linear-gradient(to top right, #2843A1, #302f31);
                        color: #FFF;
                        font-family: 'Telegraf';
                        font-weight: 300;
                        font-size: 21px;
                        line-height: 23px;
                        padding: 8px 20px 8px;
                        max-width: 302px;
                        text-align: center;
                        border-radius: 3px;
                        transition: all 0.4s;
                        opacity: 1;
                    }
                }

                .list-2 {

                    // margin-right: 10px;
                    .list-mobile {
                        display: none;

                        @media(max-width: 992px) {
                            display: block;
                            margin-top: 40px;
                        }
                    }

                    ul {
                        padding: 0;

                        li {
                            color: #433d43;
                            list-style: none;
                            padding-top: 20px;
                            font-size: 20px;
                            font-weight: 300;

                            @media(max-width: 991px) {
                                font-size: 15px;
                                line-height: 20px;
                            }
                        }

                        a {
                            color: #2843a1;
                            padding-bottom: 20px;
                            font-size: 20px;
                            font-weight: 300;
                            text-decoration: underline;

                            @media(max-width: 991px) {
                                font-size: 15px;
                                line-height: 20px;
                            }

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    img {
                        width: 100%;
                        max-width: 300px;
                    }

                }

                .list-3 {

                    ul {
                        padding: 0;

                        li {
                            color: #423D43;
                            list-style: none;
                            padding-top: 20px;
                            font-size: 20px;
                            font-weight: 300;

                            @media(max-width: 991px) {
                                font-size: 15px;
                                line-height: 20px;
                            }
                        }

                        a {
                            color: #2843a1;
                            padding-bottom: 20px;
                            font-size: 20px;
                            font-weight: 300;
                            text-decoration: underline;

                            @media(max-width: 991px) {
                                font-size: 15px;
                                line-height: 20px;
                            }

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .list-4 {
                    @media(max-width: 992px) {
                        display: none;
                    }

                    img {
                        display: none;

                        @media(max-width: 992px) {
                            display: inline;
                        }

                    }

                    ul {
                        padding: 0;

                        li {
                            color: #423D43;
                            list-style: none;
                            padding-top: 20px;
                            font-size: 20px;
                            font-weight: 300;

                            @media(max-width: 991px) {
                                font-size: 15px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }

        &.section-4 {

            position: relative;
            background-image: linear-gradient(to top right,#103f99,#2d2b2e,#2843a1);
            width: 100%;
            text-align: center;
            padding: 120px 0 130px;
            margin: 0;
            border-bottom: 4px solid #FFF;

            @media(max-width: 992px) {
                background-image: linear-gradient(to top right, #2843A1, #302f31);
                padding: 110px 0 120px;
                border-bottom: 10px solid #FFF;
            }

            p {
                color: #FFF;
                font-family: 'Telegraf';
                font-weight: 200;
                font-size: 38px;
                line-height: 42px;
                margin: 0 0 15px;
                display:inline;

                @media(max-width: 991px) {
                    font-size: 25px;
                    line-height: 27px;
                    margin: 0 0 5px;
                }
            }

            a.btn {
                font-family: 'Telegraf';
                font-weight: 200;
                font-size: 25px;
                line-height: 30px;
                color: #FFF;
                border-bottom: 1px solid #FFF;

                @media(max-width: 991px) {
                    font-size: 18px;
                    line-height: 20px;
                }

                &:hover {
                    text-decoration: none;
                    border-bottom: 1px solid transparent;
                }
            }

        }
    }

    //Componentes de MOBILE

    .section-row.section-1-mobile {
        display: none;

        @media(max-width: 992px) {
            display: block;
            background-color: #FFF;

            .list-1 {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: 30px;

                h3 {
                    font-family: 'Telegraf';
                    font-size: 28px;
                    line-height: 35px;
                    font-weight: 300;
                    color: #2843A1;
                    margin-bottom: 40px;

                    @media(max-width: 767px) {
                        font-size: 20px;
                        line-height: 25px;
                    }

                }

                .btn.btn-purple {
                    display: block;
                    background: #2843A1;
                    background-image: linear-gradient(to top right, #2843A1, #302f31);
                    color: #FFF;
                    font-family: 'Telegraf';
                    font-weight: 300;
                    font-size: 20px;
                    line-height: 22px;
                    padding: 8px 20px 8px;
                    max-width: 402px;
                    text-align: center;
                    border-radius: 3px;
                    transition: all 0.4s;
                    opacity: 1;
                }
            }
        }
    }

    .section-row.section-2-mobile {
        display: none;

        @media(max-width: 992px) {
            display: block;
            background-color: #fff;

            .content-fluid {

                background-color: #fff;

                .row {
                    background-color: #fff;

                    .img-container {
                        display: flex;
                        flex-direction: column;
                        padding: 12px 0 12px 0;
                        background-color: #fff;
                        border-bottom: solid 0.5px #B4AFB6;
                        border-top: solid 0.5px #B4AFB6;
                        margin-bottom: 50px;
                        margin-top: 50px;

                        .img-col-mobile {
                            text-align: center;

                            .img {
                                height: 30px;
                                width: 30%;
                                margin: 21px 1%;
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                                display: inline-block;
                                overflow: hidden;
                                text-indent: -999px;
                            }
                        }

                    }

                }

            }
        }
    }

    .section-row.section-3-mobile {
        display: none;

        @media(max-width: 992px) {
            //display: block;
        }
    }

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        //width: 100%;
        //height: 100%;
        object-fit: cover;
    }

}