html, body {
  height: 100%;
  background: transparent !important;
}

.section-row{
    @media(min-width: 1240px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.content-fluid{
    max-width:1540px;
    margin:0 auto;
    width:calc(100% - 40px);
    padding:0 10px;
    @media(max-width: 767px) {
        padding:0;
    }
}

.logo{
    & > g{
        transform: scale(0.5);
    }
}


.section-video-loop{
    height: 100vh;
    overflow: hidden;
    text-align: center;
    position: relative;
    z-index: 2;

    video#myVideo{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        @media screen and (max-aspect-ratio: 1920/1080) {
            height: 100%;
        }
        @media screen and (min-aspect-ratio: 1920/1080) {
            width: 100%;
        }
    }

    a.arrow-down{
        position:absolute;
        bottom:50px;
        left:50%;
        margin-left:-15px;
        background:url(../images/home/ic_arrow-down.png) center center no-repeat;
        width: 31px;
        height: 16px;
        animation: scroll-link 1s infinite 1s;
        z-index: 3;
        &:hover{
            animation-play-state: paused;
        }
    }

}
