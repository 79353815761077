body.contacto, body.contact {
    width: 100%;
    height: 100%;
    background: transparent;
    padding-top: 85px;

    @media(max-width: 1200px) {
        padding-top: 70px;
    }

    header {
        background: #FFF;
    }

    .section-row {

        &.section-1 {
            h2 {
                @media(max-width: 992px) and (min-width: 767px) {
                    font-size: 50px;
                    line-height: 55px;
                }
            }

            @media(max-width: 800px) {
                background: url('../images/contacto/bg_hero.jpg') center center no-repeat;
                background-size: cover;
            }
        }

        &.section-2 {
            background-color: #FFF;
            padding: 80px 0 70px;

            @media(max-width: 991px) {
                padding: 40px 0 40px;
            }

            h4 {
                color: #2843A1;
                font-family: 'Telegraf';
                font-weight: 300;
                font-size: 28px;
                line-height: 35px;

                @media(max-width: 991px) {
                    margin-bottom: 60px
                }
            }

            .oficinas {
                border-bottom: 2px solid #d3d2d2;
                position: relative;
                padding-bottom: 10px;
                

                @media(max-width: 991px) {
                    padding-bottom: 15px;
                }
            }

            h5 {
                color: #88858a;
                font-family: 'Manrope';
                font-weight: 400;
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 30px;

                @media (min-width:1440px) and (max-width: 1920px) {
                    font-size: 20px;
                    line-height: 25px;
                }
                @media(max-width: 1200px) {
                    font-size: 20px;
                    line-height: 40px;
                }

                @media(max-width: 991px) {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 23px;
                    margin-bottom: 20px
                }
            }

            p {
                color: #88858a;
                font-family: 'Manrope';
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 30px;


                @media(max-width: 1200px) {
                    font-size: 20px;
                    line-height: 25px;
                }

                @media(max-width: 991px) {
                    font-size: 18px;
                    line-height: 23px;
                    margin-bottom: 20px
                }
            }

            .redes {
                position: absolute;
                bottom: 40px;
                right: 0;

                @media(max-width: 991px) {
                    position: static;
                    padding-top: 20px;
                }

                span {
                    color: #2843A1;
                    font-family: 'Manrope';
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    border-bottom: 1px solid #2843A1;
                    padding: 0;
                    margin: 0 20px 0 0;
                    display: inline-block;
                }

                a.linkedin {
                    display: inline-block;
                    background: url('../images/contacto/ic-linkedin-blue.png') center center no-repeat;
                    background-size: contain;
                    width: 25px;
                    height: 26px;
                    overflow: hidden;
                    text-indent: -999px;
                    position: relative;
                    margin-bottom: -5px;
                }
            }
        }

        &.section-3 {
            background: #f2f2f2;
            padding: 90px 0 100px;

            
            @media(max-width: 991px) {
                padding: 45px 0 60px;

                .content-fluid {
                    max-width: 700px;
                    margin: 0 auto;
                }
            }
            @media (max-width:768px) {
                .align-tablet{
                    justify-content: center;
                    text-align: center;
                }
                .content-fluid{
                    max-width: 380px;
                }
                
            }
            h3 {
                color: #2843A1;
                font-family: "Telegraf";
                font-size: 40px;
                font-weight: 400;
                line-height: 45px;
                margin-bottom: 52px;
                
                @media (min-width:768px) and (max-width: 1440px) {
                    justify-content: center;                  
                }
                @media(max-width: 991px) {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                    margin-bottom: 25px
                }
            }

            .form-group {
                ::placeholder {
                    color: #2843A1;
                    font-family: "Telegraf";
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 38px;

                    @media(max-width: 991px) {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 35px;
                        padding: 2px 0px 0px;
                    }
                }

                .form-control {
                    width: 100%;
                    border: 1px solid #2843A1;
                    border-radius: 3px;
                    background-color: #f2f2f2;
                    color: #2843A1;
                    font-family: "Telegraf";
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 38px;
                    margin-bottom: 28px;
                    padding: 2px 20px 0px;

                    @media(max-width: 991px) {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 35px;
                        margin-bottom: 20px
                    }
                }

                input,
                select {
                    height: 40px;

                    @media(max-width: 991px) {
                        height: 35px;
                    }
                }

                textarea.form-control {
                    margin-bottom: 28px;
                    height: 400px;

                    @media(max-width: 991px) {
                        margin-top: 20px;
                        margin-bottom: 20px;
                        height: 200px;
                    }
                }
            }

            button {
                width: 300px;
                display: block;
                background: #2843A1;
                background-image: linear-gradient(to top right, #2843A1, #302f31);
                color: #FFF !important;
                font-family: 'Telegraf';
                font-weight: 300;
                font-size: 20px !important;
                line-height: 22px;
                padding: 8px 40px 6px !important;
                margin: 0px auto 0;
                text-align: center;
                border-radius: 3px;
                transition: all 0.4s;
                opacity: 1;
                border: 0;

                @media(max-width: 991px) {
                    font-size: 20px;
                    line-height: 22px;
                    padding: 10px 20px 8px;
                    width: 182px;
                    font-weight: 400;
                    border-radius: 4px;
                }

                &:hover {
                    opacity: 0.8;
                    text-decoration: none;
                }
            }
        }

    }


    .wpforms-submit-container{
        width:48% !important;
        float:right !important;
        position: relative;
        top: 140px;
        left:10px;
        margin-bottom:150px !important;
        @media(max-width: 991px) {
            width:100% !important;float:none !important;
            margin-bottom:0px !important;
            position: static;
            top: 0;
            left:0px;
        }
    }

    .wpforms-form label.wpforms-error {
        position: absolute;
    }
    
    .wpforms-field-container{
        .wpforms-label-hide{
            display: none;
        }
        .wpforms-field-textarea{
            position: absolute;
            top: 0px;
            right: 0;
        }
        @media(max-width: 991px) {
            .wpforms-one-half{
                width:100% !important;
                float: none;
                margin-left:0 !important;
            }
            .wpforms-first{
                float:none;
            }
            .wpforms-field-textarea{
                position: static;
            }
        }
        .wpforms-field{
            
            padding: 0px 0 !important; 
            margin-bottom: 28px;
            @media(max-width: 991px) {
                margin-bottom: 20px !important;
            }

            ::placeholder {
                color: #2843A1;
                font-family: "Telegraf";
                font-size: 25px;
                font-weight: 400;
                line-height: 38px;

                @media(max-width: 991px) {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 35px;
                    padding: 2px 0px 0px;
                }
            }

            input, textarea, select{
                width: 100%;
                border: 1px solid #2843A1 !important;
                border-radius: 3px;
                background-color: #f2f2f2 !important;
                color: #2843A1 !important;
                font-family: "Telegraf";
                font-size: 25px !important;
                font-weight: 400;
                line-height: 38px !important;
                padding: 2px 20px 0px !important;

                @media(max-width: 991px) {
                    font-weight: 500;
                    font-size: 18px !important;
                    line-height: 35px !important;
                    //margin-bottom: 20px !important;
                }
            }

            input,
            select {
                height: 40px;

                @media(max-width: 991px) {
                    height: 35px;
                }
            }

            textarea{
                //margin-bottom: 28px;
                height: 400px !important;

                @media(max-width: 991px) {
                    margin-top: 20px;
                    //margin-bottom: 20px;
                    height: 200px !important;
                }
            }
            
        }
        
        #wpforms-159-field_6-container select{
            background: url('../images/contacto/form-detail.svg') 96% center no-repeat;
            background-size: 22px auto;
            appearance: none;
            cursor: pointer;
        }
        #wpforms-319-field_6-container select{
            background: url('../images/contacto/form-detail.svg') 96% center no-repeat;
            background-size: 22px auto;
            appearance: none;
            cursor: pointer;
        }

    }

}