body.nuestros-fondos, body.our-funds {
    width: 100%;
    height: auto;
    background: transparent;
    padding-top: 85px;

    @media(max-width: 1200px) {
        padding-top: 70px;
    }

    header {
        background: #FFF;
    }

    .section-row {

        &.section-1 {
            
            @media(max-width: 800px) {
                background: url('../images/fondos/Enc_Fondos.jpg') center center no-repeat;
                background-size: cover;
                height: 35vh;
            }
        }

        &.section-2 {
            background-color: #FFF;

            .content-fluid {
                max-width: 1150px;
            }

            .accordion {
                padding: 30px 0 180px;

                .accordion-item {
                    padding: 50px 0 20px;
                    border-bottom: 1px solid #2843A1;
                    position: relative;

                    @media(max-width: 576px) {
                        padding: 35px 0 10px;
                    }
                    .accordion-button {
                        content: '';
                        background: url('../images/fondos/fondos-mas.png') center center no-repeat;
                        background-size: contain;
                        position: absolute;
                        bottom: 18px;
                        right: 20px;
                        width: 60px;
                        height: 60px;
                        z-index: 2;
                        cursor: pointer;

                        @media(max-width: 576px) {
                            width: 35px;
                            height: 35px;
                        }
                    }

                    h4 {
                        color: #2843A1;
                        font-family: 'Telegraf';
                        font-weight: 500;
                        font-size: 34px;
                        line-height: 34px;

                        @media(max-width: 576px) {
                            font-size: 23px;
                            line-height: 15px;
                        }
                    }

                    h5 {
                        color: #2843A1;
                        font-family: 'Telegraf';
                        font-weight: 200;
                        font-size: 21px;
                        line-height: 34px;
                        text-transform: uppercase;
                        letter-spacing: 0.5px;
                        margin-bottom: 0px;

                        @media(max-width: 576px) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    .fondos-list {
                        height: 0px;
                        overflow: hidden;
                        padding: 0;
                        transition: all 0.7s;
                        box-sizing: content-box;

                        .fondos-item {
                            a {
                                color: #2843A1;
                                font-family: 'Telegraf';
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 38px;
                            }
                        }
                    }

                    &.open {
                        .fondos-list {
                            padding: 25px 0 10px;
                        }

                        .accordion-button {
                            background-image: url('../images/fondos/ic-close-blue.png');
                        }
                    }
                }
            }

        }

        &.section-3 {
            background-color: #0a41a6;
            padding: 100px 0 120px;

            h5 {
                color: #FFF;
                font-family: 'Telegraf';
                font-weight: 500;
                font-size: 40px;
                line-height: 45px;
                margin-bottom: 50px;

                @media(max-width: 576px) {
                    font-size: 23px;
                    line-height: 20px;
                    margin-bottom: 40px;
                }


            }

            a.special-links-item {
                display: block;
                color: #FFF;
                font-family: 'Telegraf';
                font-weight: 300;
                font-size: 28px;
                line-height: 45px;
                text-decoration: none;

                @media(max-width: 576px) {
                    font-size: 18px;
                    line-height: 30px;
                }

                span {
                    border-bottom: 1px solid #FFF;
                }

                &:hover {
                    text-decoration: none;

                    span {
                        border: none;
                    }
                }
            }

            &.open-section-hidden {
                .top-links span i {
                    color: #8b9fcf;
                }
                .top-links a {
                    color: #8b9fcf;

                    span {
                        border-color: #8b9fcf;
                    }

                    &.active {
                        color: #FFF;

                        span {
                            border-color: #FFF;
                        }
                    }
                }
            }

            .section-hidden.opened {
                display: flex;
            }

            .section-hidden {
                padding: 60px 0 40px;
                display: none;

                h4 {
                    color: #8b9fcf;
                    font-family: 'Manrope';
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 24px;
                    margin-bottom: 20px;
                    margin-top: 40px;
                }
                
                ul{
                    padding-left:18px;
                }

                p, li {
                    font-family: 'Manrope';
                    font-weight: 200;
                    font-size: 18px;
                    line-height: 24px;
                    color: #FFF;
                    margin-bottom: 20px;

                    b {
                        font-weight: 400;
                    }

                    a{
                        color: #FFF;
                    }

                    span {
                        color: #8b9fcf;

                        a {
                            display: inline;
                            border-bottom: 1px solid #8b9fcf;
                            line-height: 20px;
                            font-size: 18px;
                            color: #8b9fcf;

                            &:hover {
                                border-bottom: 0px;
                                text-decoration: none;
                            }
                        }
                    }
                }

                a {
                    border-bottom: 1px solid #FFF;

                    &:hover {
                        border-bottom: 0px;
                        text-decoration: none;
                    }
                }

                position: relative;

                .close {
                    content: '';
                    background: url('../images/fondos/ic_close-link.png') center center no-repeat;
                    background-size: contain;
                    width: 47px;
                    height: 47px;
                    z-index: 2;
                    cursor: pointer;
                    position: absolute;
                    bottom: -15px;
                    right: 25px;
                }
            }

        }

        &.section-4 {
            background-image: linear-gradient(to top right, #103f99, #2d2b2e, #2843A1);
            padding: 70px 0 90px;
            border-bottom: 4px solid #FFF;

            a {
                color: #FFF;
                font-family: 'Telegraf';
                font-weight: 200;
                font-size: 20px;
                line-height: 35px;
                text-decoration: none;
                border-bottom: 1px solid #FFF;

                @media(max-width: 576px) {
                    font-size: 15px;
                    line-height: 30px;
                }

                &:hover {
                    border: none;
                }
            }

            span {
                color: #FFF;
                font-family: 'Telegraf';
                font-weight: 100;
                font-size: 20px;
                line-height: 35px;

                @media(max-width: 576px) {
                    font-size: 15px;
                    line-height: 30px;
                }
            }


            &.open-section-hidden {
                .top-links span i {
                    color: #8b9fcf;
                }
                .top-links a {
                    color: #8b9fcf;
                    border-bottom: 1px solid #8b9fcf;

                    span {
                        border-color: #8b9fcf;
                    }

                    &.active {
                        color: #FFF;
                        border-bottom: 1px solid #FFF;

                        span {
                            border-color: #FFF;
                        }
                    }
                }
            }

            .section-hidden.opened {
                display: flex;
            }

            .section-hidden {
                padding: 60px 0 40px;
                display: none;

                h4 {
                    color: #8b9fcf;
                    font-family: 'Manrope';
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 24px;
                    margin-bottom: 20px;
                    margin-top:40px;
                }

                ul{
                    padding-left:18px;
                }

                p, li {
                    font-family: 'Manrope';
                    font-weight: 200;
                    font-size: 18px;
                    line-height: 24px;
                    color: #FFF;
                    margin-bottom: 20px;

                    b {
                        font-weight: 400;
                    }

                    span {
                        color: #bc80d9;

                        a {
                            display: inline;
                            border-bottom: 1px solid #bc80d9;
                            line-height: 20px;
                            font-size: 18px;
                            color: #bc80d9;

                            &:hover {
                                border-bottom: 0px;
                                text-decoration: none;
                            }
                        }
                    }
                }

                a {
                    border-bottom: 1px solid #FFF;

                    &:hover {
                        border-bottom: 0px;
                        text-decoration: none;
                    }
                }

                position: relative;

                .close {
                    content: '';
                    background: url('../images/fondos/ic_close-link.png') center center no-repeat;
                    background-size: contain;
                    width: 47px;
                    height: 47px;
                    z-index: 2;
                    cursor: pointer;
                    position: absolute;
                    bottom: -15px;
                    right: 25px;
                }
            }
        }

    }

}