body.avisos-importantes,
body.important-notices,
body.legales-y-regulaciones,
body.legal-and-regulations {
    width: 100%;
    height: 100%;
    background: transparent;
    padding-top: 85px;
    @media(max-width: 1200px){
        padding-top: 70px;
    }

    header{
        background: #FFF;
    }

    .section-row{
        &.section-1{
            min-height: 200px;
            height: 30vh;
            @media(max-width: 1200px){
                min-height: 100px;
            }
            @media(max-width: 800px){
                background: url('../images/avisos-importantes/bg_hero.jpg') center center no-repeat;
                background-size: cover;
            }
            .section-content{
                min-height: 200px;
                height: 30vh;
                @media(max-width: 1200px){
                    min-height: 100px;
                }  
            }
        }

        &.section-2{
            position: relative;
            background:#F2F2F2;
            width:100%;
            padding:30px 0 150px;
            @media(max-width: 991px){
                padding:30px 0 100px;
            }
            .content-fluid{
                max-width: 1140px;
            }
            .row{
                padding-top:0;
                padding-bottom:0;
            }
            h3{
                color:#2843a1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 30px;
                line-height: 50px;
                margin-bottom:45px;
                @media(max-width: 991px){
                    font-size: 22px;
                    line-height: 25px;
                    text-align: center;
                    margin-bottom:30px;
                }
            }
            .card-container{
                padding-top:73px;
                .hide{
                    display:none;
                }
            }
            .gap{
                margin-bottom: 100px;
            }
            .gap:nth-last-child(-n+3){
                margin-bottom: 73px;
            }
            .card{
                border-radius: 3px;
                background-color: #FFF;
                overflow: hidden;
                position: relative;
                height: 420px;
                display: block;
                
                @media(max-width: 576px){
                    height: auto;
                    margin-bottom:35px;
                }
                .img-cont{
                    height: 0;
                    overflow: hidden;
                    height: 261px;
                    background: white;
                    position: relative;
                    .img{
                        background-color: #E4E4E4;
                        background-size: cover;
                        height: 100%;
                        width: 100%;
                        transition: transform 0.4s;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        padding: 40px 30px;
                        position: relative;
                        transition: all 0.4s;
                        
                    }
                    
                    
                    .img-inner{
                        background:url('../images/home/bg_blog-note.png') left bottom no-repeat;
                        background-size: contain;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                    }
                }
                h4{
                    color:#433d43;
                    font-family: 'Manrope';
                    font-weight: 300;
                    font-size: 23px;
                    line-height: 28px;
                    margin:0;
                }
                p{
                    color:#433d43;
                    font-family: 'Manrope';
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 22px;
                    max-height: 88px;
                    position: relative;
                    word-wrap: break-word;
                    overflow: hidden;
                    margin-top:25px;

                    &::after {
                        content: "...";
                        position: absolute;
                        //right: 0px; 
                        bottom: 0px;
                      }
                        
                    @media(max-width: 991px){
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom:50px;
                    }
                }
                &:hover{
                    .img{    
                        background: #2843a1;
                        background-image: radial-gradient(circle, #2843a1 35%, #7587bf 100%);
                        p,h4{
                            color:#FFF;
                        }   
                    }
                    text-decoration: none;
                }
                span.fecha{
                    display: inline-block;
                    color:#2843a1;
                    font-family: 'Manrope';
                    font-weight: 300;
                    font-size: 23px;
                    line-height: 28px;
                    padding: 35px 30px;
                    
                }
                
            }
            .btn.btn-purple{
                display: block;
                background: #2843a1;
                background-image: linear-gradient(to top right, #2843a1, #302f31);
                color:#FFF;
                font-family: 'Telegraf';
                font-weight: 300;
                font-size: 21px;
                line-height: 23px;
                padding:8px 40px 6px;
                margin:0 auto;
                max-width:200px;
                text-align: center;
                border-radius: 3px;
                transition: all 0.4s;
                opacity:1;
                @media(max-width: 991px){
                    font-size: 20px;
                    line-height: 22px;
                    padding:10px 20px 8px;
                }
                &:hover{
                    opacity:0.8;
                    text-decoration: none;
                }
                &.avisos{
                    float:right;
                    max-width:100%;
                    width:auto;
                }
            }
        }

    }

}
