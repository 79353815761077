
.popup-dialog {
    position: fixed;
    //bottom:-150px;
    bottom: 0;
    right:4vw;
    max-width: 365px;
    width:92vw;
    z-index: 1000;

    // @media(max-width: 895px){    
    //     right:4vw;
    // }

    .popup-dialog-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-image: linear-gradient(to top right,#103f99,#2d2b2e,#2843a1);
        padding: 10px 30px;
        h3 {
            color: #fff !important;
            font-family: 'Telegraf' !important;
            font-size: 23px !important;
            line-height: 30px !important;
            font-weight: 400 !important;
            margin:0 !important;
            padding: 0 !important;
            border-bottom: 0 !important;
        }

        .close {
            background: url(../images/general/av-popup-close.png) center center no-repeat;
            background-size: contain;
            width: 25px;
            height: 30px;
            color: #fff;
            margin-left: 25px;
            cursor: pointer;
        }
    }

    .popup-dialog-content {
        font-family: 'Manrope';
        font-size: 21px;
        line-height: 29px;
        font-weight: 400;
        color: #2843a1;
        padding:25px 30px 15px 30px;
        background-color: #FFF;
        a{
            color: #2843a1;
        }
    }
}