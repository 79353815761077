body.login {
    width: 100%;
    height: auto;
    background: #FFF;
    padding-top: 80px;
    @media(max-width: 1200px) {
        padding-top: 70px;
    }

    header{
        background: #2843a1;
    }

    .section-row{

        .content-fluid{
            height:calc(100vh - 85px);
            max-width: 3000px;
            overflow: hidden;
            width:100%;
            margin: 0;
            display: flex;
            justify-content: space-between;
            @media(max-width: 991px){
                height: auto;
            }

            img{
                width: 50%;
                max-width: 1920px;
                padding: 0;
                @media(max-width: 991px){
                    display: none;
                }
            }

            .log-user{
                margin-left: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                @media(max-width: 991px){
                    margin:60px auto 100px;
                }
                @media(max-width: 768px){
                    width: 100%;
                    max-width: 410px;
                    display: block;
                }
                @media(max-width: 450px){
                    margin:60px 20px 100px;
                    width: 100%;
                }
                .form-content{
                    display:flex;
                    flex-direction: column;
                    padding-bottom: 20px;
                    color:#2843a1;
                    
                    form {
                        ::placeholder{
                            color:#2843a1;
                            font-family: "Telegraf";
                            font-weight: 400;
                            line-height: 38px;
                        }
                        .form-control{
                            border:0.5px solid #2843a1;
                            border-radius: 3px;     
                        }                        
                    }

                    h3{
                        font-size: 40px;
                        line-height: 45px;
                        font-family: "Telegraf";
                        font-weight: 300;
                        margin-bottom: 20px;
                    }
                    
                    .btn.btn-purple{
                        display: block;
                        background: #2843a1;
                        background-image: linear-gradient(to top right, #2843a1, #681191);
                        color:#FFF;
                        font-family: 'Telegraf';
                        font-weight: 300;
                        font-size: 20px;
                        line-height: 22px;
                        padding:8px 40px 6px;
                        margin-top: 40px;
                        text-align: center;
                        border-radius: 3px;
                        transition: all 0.4s;
                        opacity:1;
                        @media(max-width: 991px){
                            font-size: 18px;
                            line-height: 22px;
                            padding:10px 20px 8px;
                        }
                
                        &:hover{
                            opacity:0.8;
                            text-decoration: none;
                        }
                    }
  
                    .help-user{
                        margin-top: 10px;
                        margin-bottom: 30px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        font-size: 13px;
                        color:#433E44;
                        font-family: "Telegraf";
                        font-weight: 400;
                        
                        a{
                            color:#433E44;
                            //border-bottom: 0.3px solid #433E44;
                            text-decoration: none;
                            line-height: 19px;
                            text-decoration: underline;
                            &:hover{
                                text-decoration: none;
                                //border-bottom: none;
                            }
                        }  
                    }
                    .form-check{

                        .form-check-label{
                            padding-left: 8px;
                            font-family: 'Manrope';
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 30px;
                            color:#5A555A;

                        }
                    }
                    .input-key{
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        .virtual-key{
                            padding-left: 8px;
                            font-family: 'Manrope';
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 30px;
                            color:#5A555A;
                        }
                    }
                    .user-no-registred{
                        border-top: solid 0.5px #cccacb;

                        .btn.btn-grey{
                            display: block;
                            background: #E6E6E6;
                            color:#2843a1;
                            font-family: 'Telegraf';
                            font-weight: 400;
                            font-size: 20px;
                            margin-top: 40px;
                            padding:8px 40px 6px;
                            text-align: center;
                            border-radius: 3px;
                            transition: all 0.4s;
                            opacity:1;
                            @media(max-width: 991px){
                                font-size: 18px;
                                line-height: 22px;
                                padding:10px 20px 8px;
                            }
                    
                            &:hover{
                                opacity:0.8;
                                text-decoration: none;
                            }
                        }
                    }
                }         
            }
        }   
    }   
}
