body.novedades, body.news {
    width: 100%;
    height: 100%;
    background: transparent;
    padding-top: 85px;
    @media(max-width: 1200px){
        padding-top: 70px;
    }

    header{
        background: #FFF;
    }

    .section-row{

        &.section-1{
            @media(max-width: 800px){
                background: url('../images/novedades/bg_hero.jpg') center center no-repeat;
                background-size: cover;
            }
        }

        &.section-2{
            position: relative;
            background:#F2F2F2;
            width:100%;
            padding:30px 0 150px;
            @media(max-width: 991px){
                padding:30px 0 100px;
            }
            .content-fluid{
                max-width: 1140px;
            }
            .row{
                padding-top:0;
                padding-bottom:0;
            }
            h3{
                color:#2843a1;
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 30px;
                line-height: 50px;
                margin-bottom:45px;
                @media(max-width: 991px){
                    font-size: 22px;
                    line-height: 25px;
                    text-align: center;
                    margin-bottom:30px;
                }
            }
            .card-container{
                padding-top:70px;
                padding-bottom:25px;
                .hide{
                    display:none;
                }
            }
            .card{
                border-radius: 3px;
                background-color: #FFF;
                overflow: hidden;
                position: relative;
                height: 480px;
                margin-bottom:90px;
                display: block;
                @media(max-width: 576px){
                    height: auto;
                    margin-bottom:35px;
                }
                .img-cont{
                    height: 0;
                    overflow: hidden;
                    padding-top: 241px / 363px * 100%;
                    background: white;
                    position: relative;
                    .img{
                        background:url('../images/home/bg_hero.jpg') center center no-repeat;
                        background-size: cover;
                        height: 100%;
                        width: 100%;
                        transition: transform 0.4s;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                    }
                    .img-inner{
                        background:url('../images/home/bg_blog-note.png') left bottom no-repeat;
                        background-size: contain;
                        position: absolute;
                        top: 1px;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                    }
                }
                p{
                    color:#433d43;
                    font-family: 'Telegraf';
                    font-weight: 100;
                    font-size: 23px;
                    line-height: 28px;
                    margin-bottom:40px;
                    padding: 30px 25px;
                    @media(max-width: 991px){
                        font-size: 20px;
                        line-height: 25px;
                        margin-bottom:50px;
                    }
                }
                .btn{
                    position: absolute;
                    bottom: 30px;
                    left: 25px;
                    color:#433d43;
                    font-family: 'Telegraf';
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 18px;
                    border-bottom: 1px dotted #302f31;
                    @media(max-width: 991px){
                        font-size: 16px;
                        line-height: 18px;
                    }
                    &:hover{
                        text-decoration: none;
                        border-bottom: 1px dotted transparent;
                    }
                }
                &:hover{
                    text-decoration: none;
                    .img{
                        transform: scale(1.2);
                    }
                }
            }
            .btn.btn-purple{
                display: block;
                background: #2843A1;
                background-image: linear-gradient(to top right, #2843A1, #302f31);
                color:#FFF;
                font-family: 'Telegraf';
                font-weight: 300;
                font-size: 21px;
                line-height: 23px;
                padding:8px 40px 6px;
                margin:0 auto;
                max-width:200px;
                text-align: center;
                border-radius: 3px;
                transition: all 0.4s;
                opacity:1;
                @media(max-width: 991px){
                    font-size: 20px;
                    line-height: 22px;
                    padding:10px 20px 8px;
                }
                &:hover{
                    opacity:0.8;
                    text-decoration: none;
                }
                &.avisos{
                    float:right;
                    max-width:100%;
                    width:auto;
                }
            }
        }

    }

}
