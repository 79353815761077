body.valores-cuotaparte header,
body.share-values header,
body.single-fondos_de_inversion header {
    background: #FFF;
}
body.valores-cuotaparte,
body.share-values,
body.single-fondos_de_inversion{
    padding-top:100px;
}

body.single-fondos_de_inversion{
    iframe{
        border:0;
    }
}

body.valores-cuotaparte,
body.share-values {

    .content-fluid {
        max-width: 1150px;
    }

    h3{
        font-family: $font-family-text;
        font-weight: 300;
        font-size: 50px;
        line-height: 55px;
        max-width: 580px;
        color: #2843a1;
        padding:60px 0 30px;
        @media(max-width: 576px) {
            font-size: 30px;
            line-height: 40px;
            padding:30px 0 20px;
        }
    }

    a.volver-link {
        display: block;
        color: #2843a1;
        font-family: 'Telegraf';
        font-weight: 300;
        font-size: 22px;
        line-height: 35px;
        text-decoration: none;
        position:absolute;
        right: 0;
        top: 90px;

        @media(max-width: 767px) {
            font-size: 18px;
            line-height: 30px;
            position:static;
            float: right;
            margin-bottom:20px;
        }

        span {
            border-bottom: 1px solid #2843a1;
        }

        &:hover {
            text-decoration: none;

            span {
                border: none;
            }
        }
    }
    .table-container{
        @media(max-width: 767px) {
            width:100%;
            overflow: scroll;
        }
        table{
            width:100%;
            border-top:1px solid #e2e2e2;
            border-left:1px solid #e2e2e2;
            border-right:1px solid #e2e2e2;
            td,th{
                padding-left:4px;
                padding-right:4px;
            }
            th{
                padding-top:3px;
                padding-bottom:3px;
            }
        }
    }
    thead {
        background-color: #fffffe;
        color: #747474;
        font-size: 12px;
        font-weight: 500;
        tr{
            border-bottom:1px solid #e2e2e2;
        }
    }
    tbody tr{
        border-bottom:1px solid #e2e2e2;
        font-size: 12px;
        font-weight: 500;
        color: #747474;
    }
    tbody tr:nth-child(odd) {
        background-color: #f0f2f4;
    }
    tbody tr:nth-child(even) {
        background-color: #ffffff;
    }
    div.info{
        display: block;
        font-family: 'Manrope';
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        color:#2c2b2b;
        padding:20px;
        background: #f0f2f4;
        margin: 30px 0;
        @media(max-width: 767px) {
            font-size: 12px;
            line-height: 15px;
        }
    }
}

body{
    @media(min-width:1440px){

        .row {
            margin-right: -25px;
            margin-left: -25px;
        }

        .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
            padding-right: 25px;
            padding-left: 25px;
        }

    }
}