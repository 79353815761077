footer {

    .footer-top {
        background-color: #2c2b2b;
        padding: 45px 0 45px 0;

        .content-fluid {
            padding: 0;
            @media(min-width: 1240px) {
                padding-left: 30px !important;
                padding-right: 30px !important;
            }
            @media(max-width: 1240px) {
                padding:0 10px;
            }
            @media(max-width: 767px) {
                padding:0;
            }

            .content-group{
                // display:flex;
                // flex-direction: row;
                
                .fila2-3-mobile{
                    display: none;
                    @media(max-width: 1199px) {
                        display: block;
                        margin-bottom: 43px;
                        a{
                            text-align: left;
                            font-family: $font-family-text;
                            font-weight: 400;
                            margin: 0;
                            color: #818181;
                            font-size: 18px;
                            line-height: 25px;
                            
                        }
                    } 
                } 
                .assets-mobile{
                    margin-bottom:25px ;
                    p{
                        display:none;
                        text-align: left;
                        font-family: 'Manrope';
                        font-weight: 300;
                        color: #979797;
                        font-size: 13px;
                        line-height: 20px;
                        margin:0;
                        padding-bottom: 10px;
                        @media(max-width: 1199px) {
                            display: block;
                        }
                    }
                }
                .fila2-2-mobile{
                    display:none;
                    @media(max-width: 1199px) {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-bottom: 25px;
                    }
                }
                .fila1{
                    display:flex; 
                    margin-bottom: 70px; 
                    .fila1-1{
                        @media(max-width: 1199px) {
                            display: none;
                        } 
                    }
                    .fila1-2{
                        @media(max-width: 1199px) {
                            display: none;
                        } 
                    }                                                 
                }
                .fila2{
                    display:flex;
                    margin-bottom: 27px;
                    .fila2-1-imgContent{
                        margin-bottom: 25px;
                        display:flex;
                        column-gap: 10px;

                        a {
                            text-align: left;
                            font-family: $font-family-text;
                            font-weight: 400;
                            margin: 0;
                            color: #818181;
                            font-size: 18px;
                            align-items: center;
                            display: flex;
                            
                        }

                        .privacy{
                            padding-left: 10px;
                            text-decoration: underline;
                            &:hover{
                                text-decoration: none;
                            }
                            
                        }
                    }
                    .assets {
                        padding-right:10px;
                        p{
                            text-align: left;
                            font-family: 'Manrope';
                            font-weight: 300;
                            margin: 0;
                            color: #979797;
                            font-size: 13px;
                            line-height: 20px;
                            // padding-right: 10px;
                            @media(max-width: 1199px) {
                                display:none;
                            }
                        }
                    }
                    .fila2-2{
                        display: flex;
                        flex-direction: column;
                        row-gap: 18px;
                        align-self: flex-end;
                        @media(max-width: 1199px) {
                            display:none;
                        }
                    }
                    .fila2-3{
                        display: flex;
                        align-self: flex-end;
                        @media(max-width: 1199px) {
                            display:none;
                        }
                        a{
                            text-align: left;
                            font-family: $font-family-text;
                            font-weight: 400;
                            margin: 0;
                            color: #818181;
                            font-size: 18px;
                            line-height: 60px; 
                            text-decoration: underline;
                            &:hover{
                                text-decoration: none;
                            }                           
                        }
                    }
                }
                
            }
            .text-bottom p{
                text-align: left;
                font-family: 'Manrope';
                font-weight: 300;
                margin: 0;
                color: #979797;
                font-size: 11px;
                line-height: 12px;
            }
            
        }

        // .afip {
        //     position: absolute;
        //     top: 0;
        //     right: 0;
            

        //     @media(max-width: 1220px) {
        //         top: 30px;
        //     }

        //     @media(max-width: 1199px) {
        //         display: none;
        //     }
        // }

        a.logo {
            img {
                width: 259px;
                height: 51px;
                
            }

            @media(max-width: 1199px) {
                display: none;
            }
        }

        ul.navbar {
            padding: 0 0 30px;
            margin: 0;

            @media(max-width: 1199px) {
                display: none;
            }

            li {
                float: left;
                width: 100%;
                list-style: none;

                a {
                    font-family: $font-family-text;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 25px;
                    color: #FFF;
                }
            }
        }

        p {
            font-family: $font-family-text;
            font-weight: 300;
            font-size: 18px;
            line-height: 25px;
            color: #FFF;

            @media(max-width: 991px) {
                font-size: 17px;
                line-height: 22px;
            }
        }

        .hidden-lg.col-sm-12 {

            //display: none;
            @media(max-width: 991px) {
                //display: block;
            }
        }

        .hidden-sm {
            @media(max-width: 991px) {
                //display: none;
            }
        }

    }

    .linkedin {
        display: inline-block;
        background: url('../images/general/ic_linkedin.png') center center no-repeat;
        background-size: contain;
        width: 22px;
        height: 22px;
        overflow: hidden;
        text-indent: -999px;
        position: relative;
        margin-bottom: -15px;
    }

    .footer-bottom {

        background-color: #2c2b2b;
        padding: 0px 0 40px;
        color: #979797;

        @media(max-width: 991px) {
            padding: 10px 0 50px;
        }

        .comisiones-box {
            a {
                color: #979797;
                font-family: $font-family-text;
                font-weight: 300;
                font-size: 18px;
                line-height: 25px;
                display: block;

                @media(max-width: 991px) {
                    font-size: 15px;
                    line-height: 25px;
                }
            }

            padding:0 0 30px;

            @media(max-width: 991px) {
                padding: 0 0 10px;
            }
        }

        p {
            text-align: left;
            font-family: 'Manrope';
            font-weight: 300;
            margin: 0;
            color: #979797;
        }

        .par2-cont p{
            font-size: 12px;
            line-height: 13px;
            padding: 0 0 40px;
            color: #8c8c8c;

            @media(max-width: 991px) {
                font-size: 11px;
                line-height: 12px;
                padding: 10px 0 15px;
            }
        }

        .par3-cont p{
            font-size: 11px;
            line-height: 13px;
            color: #8c8c8c;

            @media(max-width: 991px) {
                font-size: 11px;
                line-height: 12px;
            }
        }

        .par1-cont {
            display:flex;
            flex-direction: column;
            margin-bottom: 20px;
            .par1-cont-imgtext{
                display: flex;
                column-gap: 60px;
                .par1-cont-text{
                    display: flex;
                    flex-direction: column;
                    align-self: flex-end;
                }
                .par1-cont-img{
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                }
            }

            & > p {
                font-size: 13px;
                line-height: 18px;
    
                @media(max-width: 991px) {
                    font-size: 13px;
                    line-height: 15px;
                    padding-bottom: 10px;
                }
            }
        }

    }

}