body.novedades-post, body.single-post, body.single-avisos_importantes {
    width: 100%;
    height: auto;
    background: #FFF;
    padding-top: 85px;

    @media(max-width: 1200px) {
        padding-top: 70px;
    }

    header {
        background: #FFF;
    }

    .section-row {

        .content-fluid {
            max-width: 1150px;
        }

        img {
            width: 100%;
            height: auto;
            margin-top: 70px;

            @media(max-width: 520px) {
                margin-top: 30px;
                margin-left: -20px;
                margin-right: -20px;
                position: relative;
                width: calc(100% + 40px);
            }
        }

        .title {
            display: flex;
            justify-content: space-between;
            padding: 45px 0 60px 0;
            flex-direction: column;

            @media(max-width: 520px) {
                padding: 40px 0 10px 0;
            }

            h1 {
                font-family: 'Telegraf';
                font-weight: 400;
                font-size: 30px;
                line-height: 28px;
                color: #2843A1;

                @media(max-width: 520px) {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 22px;
                }
            }

            .desktopDisplay {
                font-family: 'Manrope';
                font-weight: 300;
                font-size: 18px;
                line-height: 22px;
                color: #2843A1;

                @media(max-width: 520px) {
                    display: none;
                }
            }

            .mobileDisplay {
                display: none;
                font-family: 'Manrope';
                font-weight: 300;
                font-size: 18px;
                line-height: 22px;
                color: #2843A1;

                @media(max-width: 520px) {
                    display: block;
                    font-size: 15px;
                    line-height: 22px;
                    margin-bottom: 15px;
                }
            }
        }

        .videoMobile {
            display: none;

            @media(max-width: 520px) {
                display: block;
                margin-bottom: 50px;
            }
        }

        .videoDesktop {
            display: inline-block;

            @media(max-width: 520px) {
                display: none;
            }
        }

        h3 {
            font-family: 'Manrope';
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            color: #4A454A;
            margin-bottom: 30px;

            @media(max-width: 520px) {
                font-size: 18px;
            }
        }

        h2 {
            font-family: 'Manrope';
            font-weight: bold;
            font-size: 22px;
            line-height: 26px;
            color: #4A454A;
            margin-bottom: 30px;

            @media(max-width: 520px) {
                font-size: 20px;
            }
        }

        .list-title {
            font-style: italic;
            font-weight: 400;
        }

        ul {
            padding-left: 50px;
            font-family: 'Manrope';
            font-style: normal;
            font-size: 20px;
            line-height: 26px;
            color: #4A454A;
            margin-bottom: 30px;

            @media(max-width: 520px) {
                font-size: 15px;
            }
        }

        .btn.btn-purple {
            display: block;
            background: #2843A1;
            background-image: linear-gradient(to top right, #2843A1, #302f31);
            color: #FFF;
            font-family: 'Telegraf';
            font-weight: 300;
            font-size: 21px;
            line-height: 23px;
            padding: 8px 40px 6px;
            margin: 100px auto;
            max-width: 200px;
            text-align: center;
            border-radius: 3px;
            transition: all 0.4s;
            opacity: 1;

            @media(max-width: 991px) {
                font-size: 20px;
                line-height: 22px;
                padding: 10px 20px 8px;
            }

            &:hover {
                opacity: 0.8;
                text-decoration: none;
            }

        }
    }
}