
/*IMPORT FONTS*/

/*GOOGLE FONTS*/
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');

// /*MANROPE*/
// @font-face {
//     font-family: 'Manrope';
//     src:url('../fonts/Manrope-ExtraLight.ttf')  format('truetype');
//     font-weight: 200;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Manrope';
//     src:url('../fonts/Manrope-Light.ttf')  format('truetype');
//     font-weight: 300;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Manrope';
//     src:url('../fonts/Manrope-Regular.ttf')  format('truetype');
//     font-weight: 400;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Manrope';
//     src:url('../fonts/Manrope-Medium.ttf')  format('truetype');
//     font-weight: 500;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Manrope';
//     src:url('../fonts/Manrope-SemiBold.ttf')  format('truetype');
//     font-weight: 600;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Manrope';
//     src:url('../fonts/Manrope-Bold.ttf')  format('truetype');
//     font-weight: 700;
//     font-style: normal;
// }

/*Telegraf*/
@font-face {
    font-family: 'Telegraf';
    src:url('../fonts/Telegraf-UltraLight.otf')  format('opentype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Telegraf';
    src:url('../fonts/Telegraf-Light.otf')  format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Telegraf';
    src:url('../fonts/Telegraf-Regular.otf')  format('opentype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Telegraf';
    src:url('../fonts/Telegraf-Medium.otf')  format('opentype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Telegraf';
    src:url('../fonts/Telegraf-Bold.otf')  format('opentype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Telegraf';
    src:url('../fonts/Telegraf-UltraBold.otf')  format('opentype');
    font-weight: 800;
    font-style: normal;
}



/*VARIABLES*/

$font-family-text: 'Telegraf', sans-serif;
$font-family-text2: 'Telegraf', sans-serif;
$font-family-title: 'Telegraf', sans-serif;
