@keyframes menu-toggle {
    0% {
        opacity: 0;
    }

    50% {}

    100% {
        opacity: 1;
    }
}

@keyframes menu-toggle-close {
    0% {
        opacity: 1;
    }

    50% {}

    100% {
        opacity: 0;
    }
}

.scroll-down header {
    transform: translate3d(0, -100%, 0);
    .inner-header{
        visibility: visible !important;
    }
}

.scroll-up header {
    transform: none;
    .inner-header{
        visibility: visible !important;
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: transform 0.4s, background-color 0.4s;

    &.purple-menu{
        background-color: #FFF;
        nav.navbar ul li a{
            color: #2843A1 !important;
        }
        h1.logo a {
            display: block;
            width: 259px;
            height: 51px;
            background-image: url(../images/general/logo-azul.png) !important;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            margin-top: 7px;
        }
        nav.navbar a.btn{
            color:#2843A1 !important;
        }
        nav.navbar .idioma a.active{
            color:#2843A1 !important;

        }
        nav.navbar .idioma .sep{
            color:#2843A1 !important;
        }
    }



    .inner-header {
        width: 1540px;
        margin: 0 auto;

        @media(max-width: 1640px) {
            width: calc(100% - 40px);
            padding:0 30px;
        }
        @media(max-width: 1240px) {
            padding:0 10px;
        }
        @media(max-width: 767px) {
            padding:0;
        }
    }

    h1.logo {
        float: left;
        padding: 13px 0 5px;

        @media(max-width: 1275px) {
            padding: 8px 0 5px;
        }

        a {
            display: block;
            width: 259px;
            height: 51px;

            background-image:url('../images/general/logo-azul.png');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            margin-top: 7px;

            @media(max-width: 1275px) {
                width: 195px;
                height: 42px;
            }
            
            img {
                display: none;
                width: 259px;
                height: 51px;

                @media(max-width: 1275px) {
                    width: 215px;
                    height: 42px;
                }
            }
        }

    }

    nav.navbar {
        float: right;
        padding: 35px 0 0;

        ul {
            float: left;
            padding-left: 0;
            margin-bottom: 0;

            li {
                float: left;
                list-style: none;
                padding: 3px 0 19px 30px;
                z-index: 2;
                
                @media(max-width: 1390px) {
                    padding: 3px 0 18px 20px;
                }
                @media(max-width: 1250px) {
                    padding: 3px 0 18px 17px;
                }
                
                a {
                    font-family: $font-family-text;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 19px;
                    color: #2843A1;
                    // opacity: .75;
                }
                &.active a{
                    font-weight: 500;

                }

                // &:hover {
                //     // .menu-fondos{top: 80px;opacity: 1;}
                // }
            }
        }

        a.btn {
            display: block;
            float: left;
            font-family: $font-family-text;
            font-weight: 300;
            font-size: 18px;
            line-height: 20px;
            padding: 5px 20px;
            border: 1px solid #2843A1;
            border-radius: 3px;
            color: #2843A1;
            margin: 0 55px;
            transition: background 0.3s, color 0.3s;

            @media(max-width: 1390px) {
                margin: 0 20px;
            }

            &:hover {
                text-decoration: none;
                color: #FFF !important;
                background: #2843A1;
            }
        }

        .idioma {
            float: left;
            font-family: $font-family-text;
            font-weight: 300;
            font-size: 18px;
            line-height: 19px;
            padding: 7px 0 0;

            a {
                color: #687278;
                display: inline-block;

                &.active {
                    color: #2843A1;
                    cursor: default;
                    text-decoration: none !important;
                    font-weight: 500;
                }
            }

            .sep {
                color: #2843A1;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                display: inline-block;
                position: relative;
                top: -2px;
                padding: 0 8px 0 5px;
            }
        }

        @media(max-width: 1275px) {
            display: none;
        }
    }

}

//MENU DE FONDOS
body.scroll-down .menu-fondos.show-menu-fondos {
    opacity: 0;
    top: -680px;
}

header .menu-fondos,
header .nav .menu-fondos,
.menu-fondos {
    //display: none;
    background-color: #f2f2f2;//#e9e0ec;
    position: fixed;
    top: -680px;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 20px 0 50px;
    transition: opacity 0.3s, top 0.3s;
    opacity: 0;
    z-index: 6;
    @media(max-width: 1275px) {
        display: none;
    }

    &.show-menu-fondos {
        opacity: 1;
        top: 80px;
    }

    .row {
        max-width: 1800px;
        margin: 0 auto;
    }

    h5 {
        font-weight: 600;
        font-family: $font-family-text;
        font-size: 18px;
        line-height: 30px;
        color: #2843A1;
        margin-bottom: 20px;
    }

    a {
        font-family: $font-family-text;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        color: #2843A1;
        border-bottom: 1px solid #2843A1;
        margin-top: 5px;
        display: inline-block;

        &:hover {
            border: 0;
            text-decoration: none;
        }
    }

    ul {
        float: none;
        padding-left: 0;

        li {
            float: left;
            width: 100%;
            list-style: none;
            padding: 3px 0 0;
            margin-top: 0;
            margin-bottom: 6px;
            position: relative;

            a {
                border: 0px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

}

//MOBILE
.mobile-btn {
    display: none;
    background: url('../images/general/ic-menu.png') center center no-repeat;
    cursor: pointer;

    @media(max-width: 1275px) {
        display: block;
        position: absolute;
        top: 25px;
        right: 30px;
        width: 40px;
        height: 28px;
        background-size: contain;
    }
    @media(max-width: 767px) {
        right: 20px;
    }
}

.header-mobile {
    display: none;

    @media(max-width: 1275px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #0941a5;
        z-index: 100;
        padding: 0 20px;
        animation-fill-mode: forwards;


        &.active {
            display: block;
            animation: menu-toggle 0.6s 1 0s;

            .mobile-btn {
                background-image: url('../images/general/ic_menu-close.png');
            }
        }

        h1.logo {
            margin-top: 8px;

            a {
                display: block;
                background-image:url('../images/general/ic_logo.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                width: 215px;
                height: 42px;
                margin-top: 20px;
                img {
                    display: none;
                    width: 215px;
                    height: 42px;
                }
            }

        }

        nav.navbar {
            ul {
                margin: 100px 0 35px;
                padding: 0;

                li {
                    list-style: none;

                    a {
                        font-family: $font-family-title;
                        font-size: 50px;
                        line-height: 70px;
                        color: #FFF;

                        @media(max-width: 1024px) and (min-width: 767px) {
                            font-size: 45px;
                            line-height: 60px;
                        }

                        @media(max-width: 767px) {
                            font-size: 35px;
                            line-height: 50px;
                        }

                        span,
                        br {
                            display: none;

                            @media(max-width: 500px) {
                                display: inline;
                            }
                        }

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        a.btn {
            display: inline-block;
            font-family: $font-family-text;
            font-weight: 300;
            font-size: 25px;
            line-height: 45px;
            padding: 0px 40px;
            border: 1px solid #fff;
            border-radius: 5px;
            color: #fff;
            // margin: 30px 0 20px 35px;
        }

        .idioma {
            text-align: right;
            font-family: $font-family-text;
            font-weight: 300;
            font-size: 19px;
            line-height: 20px;
            padding: 7px 0 0;

            a {
                color: #687278;
                display: inline-block;

                &.active {
                    color: #FFF;
                    cursor: default;
                    text-decoration: none !important;
                    font-weight: 500;
                }
            }

            .sep {
                color: #FFF;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                display: inline-block;
                position: relative;
                top: -2px;
                padding: 0 8px 0 5px;
            }
        }
    }
}
body.home,
body.avisos-importantes, 
body.legales-y-regulaciones,
body.legal-and-regulations,
body.single-avisos_importantes,
body.single-post{
    header nav.navbar ul li a{
        opacity: 1;
    }
} 

body.home header{
    
    h1.logo a{
        background-image:url('../images/general/ic_logo.png');
    }
    .mobile-btn{
        background-image: url('../images/general/ic_mobile-menu.png');
    }
    nav.navbar{
        ul li a{
            color:#FFF;
        }
        a.btn{
            color:#FFF;
        }
        .idioma a.active{
            color:#FFF;
        }
        .idioma .sep{
            color:#FFF;
        }
    }
    &.on-scroll{
        h1.logo a{
            background-image:url('../images/general/logo-azul.png');
        }
        .mobile-btn{
            background-image: url('../images/general/ic-menu.png');
        }
        nav.navbar{
            ul li a{
                color:#2843a1;
            }
            a.btn{
                color:#2843a1;
            }
            .idioma a.active{
                color:#2843a1;
            }
            .idioma .sep{
                color:#2843a1;
            }
        }
    }

}

