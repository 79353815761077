.section-row {

    &.section-1 {
        position: relative;
        width: 100%;
        height: 42vh;

        @media(max-width: 1200px) {
            height: 30vh;
        }

        .section-content {
            position: relative;
            height: 42vh;

            @media(max-width: 1200px) {
                height: 30vh;
            }
        }

        h2 {
            position: absolute;
            bottom: 60px;
            left: 0;
            color: #FFF;
            font-family: 'Telegraf';
            font-weight: 300;
            font-size: 57px;
            line-height: 65px;
            text-shadow: 0 0px 6px rgba(0, 0, 0, 0.50);
            
            @media (min-width:1440px) and (max-width: 1920px) {
                font-size: 50px;
            }

            @media(max-width: 1200px) {
                font-size: 35px;
                line-height: 30px;
                bottom: 20px;
            }

            @media(max-width: 768px) {
                font-size: 45px;
                line-height: 40px;
                bottom: 20px;
            }
        }
    }
}